export enum ACTION {
  ADD_NODE,
  DELETE_NODE,
  ASSIGN_PLAYER,
  CHANGE_INFO_SETS,
  ZERO_SUM_TOGGLE,
  ASSIGN_RANDOM_PAYOFFS,
  FRACTION_DECIMAL_TOGGLE,
  INCREASE_PLAYERS_COUNT,
  DECREASE_PLAYERS_COUNT,
  CHANGE_WIDTH,
  CHANGE_HEIGHT,
  CHANGE_PLAYER_MOVES_LIST,
  CHANGE_MOVE_LABEL,
  CHANGE_PLAYER_LABEL,
  CHANGE_PAYOFF,
  MOVE_TREE
}
