import {StrategicForm} from '../Model/StrategicForm';
import {GTE_VERSION} from './Constants';
import Fraction from 'fraction.js/fraction';

export class StrategicFormSerializer {
  private strategicForm: StrategicForm;

  constructor(strategicForm: StrategicForm) {
    this.strategicForm = strategicForm;
  }

  toText(title?: string) {
    let result = '';

    // Title
    result += 'Strategic Form Generated by ' + GTE_VERSION + '\n\n';
    if (title) {
      result += 'Title: ' + title + '\n';
    }
    // Players
    result += 'Players: {' + this.strategicForm.tree.players[1].label + ', ' + this.strategicForm.tree.players[2].label;
    if (this.strategicForm.p3Strategies.length !== 0) {
      result += ', ' + this.strategicForm.tree.players[3].label;
    }
    if (this.strategicForm.p4Strategies.length !== 0) {
      result += ', ' + this.strategicForm.tree.players[4].label;
    }
    result += '}\n';

    // Strategies
    const maxMoveLength = this.getMaxMoveLength();
    let separator;
    if (maxMoveLength === 1) {
      separator = '';
    } else {
      separator = ' ';
    }
    const p1RowsSeparated = this.getSeparatedStrategies(this.strategicForm.p1rows, separator);
    const p2ColsSeparated = this.getSeparatedStrategies(this.strategicForm.p2cols, separator);
    const p3RowsSeparated = this.getSeparatedStrategies(this.strategicForm.p3rows, separator);
    const p4ColsSeparated = this.getSeparatedStrategies(this.strategicForm.p4cols, separator);
    result += 'Strategies for P1: {' + p1RowsSeparated.join(', ') + '} as {' +
      this.getStrategiesToNum(this.strategicForm.p1rows) + '}\n';
    result += 'Strategies for P2: {' + p2ColsSeparated.join(', ') + '} as {' +
      this.getStrategiesToNum(this.strategicForm.p2cols) + '}\n';
    if (this.strategicForm.p3Strategies.length !== 0) {
      result += 'Strategies for P3: {' + p3RowsSeparated.join(', ') + '} as {' +
        this.getStrategiesToNum(this.strategicForm.p3rows) + '}\n';
    }
    if (this.strategicForm.p4Strategies.length !== 0) {
      result += 'Strategies for P4: {' + p4ColsSeparated.join(', ') + '} as {' +
        this.getStrategiesToNum(this.strategicForm.p4cols) + '}\n';
    }

    result += '\nStrategic Form: ';


    for (let l = 0; l < this.strategicForm.payoffsMatrix[0][0][0].length; l++) {
      for (let k = 0; k < this.strategicForm.payoffsMatrix[0][0].length; k++) {
        for (let j = 0; j < this.strategicForm.payoffsMatrix[0].length; j++) {
          for (let i = 0; i < this.strategicForm.payoffsMatrix.length; i++) {
            if (this.strategicForm.p3Strategies.length !== 0) {
              this.strategicForm.payoffsMatrix[i][j][k][l].setPlayersCount(3);
            }
            if (this.strategicForm.p4Strategies.length !== 0) {
              this.strategicForm.payoffsMatrix[i][j][k][l].setPlayersCount(4);
            }
            const kAsString = this.strategicForm.p3Strategies.length === 0 ? '' : k + ' ';
            const lAsString = this.strategicForm.p4Strategies.length === 0 ? '' : l + ' ';

            result += '\n' + i + ' ' + j + ' ' + kAsString + lAsString + ': ' +
              this.strategicForm.payoffsMatrix[i][j][k][l].toString();
          }
        }
      }
    }

    return result;
  }

  private getStrategiesToNum(strategies: Array<string>) {
    let strategiesAsNum = [];

    for (let i = 0; i < strategies.length; i++) {
      strategiesAsNum.push(i);
    }

    const result = strategiesAsNum.join(', ');
    strategiesAsNum = null;
    return result;
  }

  private getMaxMoveLength() {
    const allStrategies = this.strategicForm.p1rows.concat(this.strategicForm.p2cols, this.strategicForm.p3rows, this.strategicForm.p4cols);
    let maxMoveLength = -1;
    allStrategies.forEach((strat: string) => {
      const moves = strat.split(' ');
      moves.forEach((move: string) => {
        if (move.length > maxMoveLength) {
          maxMoveLength = move.length;
        }
      });
    });
    return maxMoveLength;
  }

  private getSeparatedStrategies(strategies: Array<string>, separator: string) {
    const result = [];
    strategies.forEach((strat) => {
      result.push(strat.split(' ').join(separator));
    });
    return result;
  }

  toTex() {
    let result = '\\documentclass[12pt]{article}\n' +
      '\\usepackage{color}\n' +
      '\\usepackage{bimatrixgame}\n' +
      '\\renewcommand{\\bimatrixrowcolor}{Red}\n' +
      '\\renewcommand{\\bimatrixcolumncolor}{Blue}\n' +
      '\\begin{document}\n' +
      '\n' +
      '\\bimatrixgame{1em}';

    // Rows and Columns
    const rows = this.strategicForm.p1rows.length;
    const cols = this.strategicForm.p2cols.length;
    result += '{' + rows + '}' + '{' + cols + '}';
    // Player names
    result += '{' + this.strategicForm.tree.players[1].label + '}' + '{' + this.strategicForm.tree.players[2].label + '}%\n';
    // Strategy names
    result += '{';
    this.strategicForm.p1rows.forEach((row: string) => {
      result += '{$' + row + '$}';
    });
    result += '}%\n';
    result += '{';
    this.strategicForm.p2cols.forEach((col: string) => {
      result += '{$' + col + '$}';
    });
    result += '}\n{\n';
    // Payoffs
    for (let i = 0; i < rows; i++) {
      result += '\\payoffpairs{' + (i + 1) + '}{';
      for (let j = 0; j < cols; j++) {
        const payoff = this.strategicForm.payoffsMatrix[i][j][0][0].outcomes[0];
        const isBestResponse = this.strategicForm.payoffsMatrix[i][j][0][0].isBestResponce[0];
        result = this.fillResultPerPlayerPayoff(result, payoff, isBestResponse);
      }
      result += '}{';
      for (let j = 0; j < cols; j++) {
        const payoff = this.strategicForm.payoffsMatrix[i][j][0][0].outcomes[1];
        const isBestResponse = this.strategicForm.payoffsMatrix[i][j][0][0].isBestResponce[1];
        result = this.fillResultPerPlayerPayoff(result, payoff, isBestResponse);
      }
      result += '}\n';
    }
    // Document end
    result += '}\n\n\\end{document}';
    return result;
  }

  private fillResultPerPlayerPayoff(result: string, payoff: Fraction, isBestResponse: boolean) {
    if (isBestResponse) {
      result += '{\\fbox';
    }
    if (payoff.d === 1) {
      result += '{$' + (payoff.n * payoff.s) + '$}';
      if (isBestResponse) {
        result += '}';
      }
    } else {
      result += '{$\\frac{' + (payoff.n * payoff.s) + '}{' + payoff.d + '}$}';
      if (isBestResponse) {
        result += '}';
      }
    }
    return result;
  }
}
